import React, { FC, ReactNode } from 'react';

import { CheckmarkIconFilled } from '@custom-components/CheckmarkIconFilled';
import { Stack, Text } from '@sparky';
import { CheckIcon } from '@sparky/icons';
import { StackProps } from '@sparky/types';

type Props = Pick<StackProps, 'gap'>;
type iconType = 'checkmark-filled';

interface ItemProps {
  gap?: Props['gap'];
  alignY?: StackProps['alignY'];
  icon?: iconType;
  children: string | ReactNode;
}

const icons = {
  'checkmark-filled': <CheckmarkIconFilled />,
};

const Item: FC<ItemProps> = ({ icon, gap = '1', alignY = 'center', children }) => (
  <Stack alignY={alignY} direction="row" gap={gap} as="li">
    <Stack.Item>{icon ? icons[icon] : <CheckIcon color="feedbackSuccess" />}</Stack.Item>
    {typeof children === 'string' ? <Text>{children}</Text> : children}
  </Stack>
);

export const Checklist: FC<React.PropsWithChildren<Props>> & { Item: typeof Item } = ({ children, gap = '1' }) => (
  <Stack direction="column" alignY="center" gap={gap} as="ul">
    {children}
  </Stack>
);

Checklist.Item = Item;

import RichText from '@components/RichText/RichText';
import { Checklist } from '@components/ui/Checklist/Checklist';
import { useLinkComponent } from '@link';
import { Placeholder } from '@sitecore/common';
import { wrap } from '@sitecore/common';
import { HeroCardRendering } from '@sitecore/types/HeroCard';
import { CustomHeroCardRendering } from '@sitecore/types/manual/HeroCard';
import { Heading, Stack, ButtonLink, Card, Box, Text, TextLink, Stretch, Bleed, Ribbon } from '@sparky';

const HeroCard: React.FC<HeroCardRendering & CustomHeroCardRendering> = ({
  fields: { title, content, buttonLink, link, footerLink, footerText, ribbonContent, uspList },
  placeholders,
  params,
}) => {
  const Link = useLinkComponent();

  return (
    <Card overflow="hidden">
      {(ribbonContent?.value || ribbonContent?.editable) && (
        <Ribbon size="large"> {wrap(ribbonContent, <RichText html={ribbonContent.value} />)}</Ribbon>
      )}
      <Box padding={{ initial: '6', md: '10' }}>
        <Stack gap="6">
          {(title.value || title.editable) && (
            <Heading size={{ initial: 'S', md: 'L' }} as="h1">
              {wrap(title)}
            </Heading>
          )}

          {(content.value || content.editable) && (
            <Bleed top="2">
              <Text size="BodyL">{wrap(content, <RichText html={content.value} />)}</Text>
            </Bleed>
          )}

          {/* ToDo: Decide wether to remove or keep this code based on results of A/B test. Contact: Team Propositions / Olivier */}
          {uspList?.value?.enum?.length && (
            <Checklist>
              {uspList.value.enum.map(usp => {
                return (
                  <Checklist.Item alignY="start" key={usp.name}>
                    {usp.label}
                  </Checklist.Item>
                );
              })}
            </Checklist>
          )}

          {!!placeholders?.['jss-hero-card-main']?.length && (
            <Box>
              <Placeholder name="jss-hero-card-main" />
            </Box>
          )}

          {((buttonLink.value.href && buttonLink.value.text) || buttonLink.editable) && (
            <Stretch width={{ initial: true, md: false }}>
              <Link editable={buttonLink.editable} linkType={buttonLink.value.linktype} linkValue={buttonLink.value}>
                <ButtonLink target={buttonLink.value.target}>{buttonLink.value.text}</ButtonLink>
              </Link>
            </Stretch>
          )}

          {((link.value.href && link.value.text) || link.editable) && (
            <Stretch width={{ initial: true, md: false }}>
              <Link editable={link.editable} linkType={link.value.linktype} linkValue={link.value}>
                <TextLink emphasis="high" target={link.value.target}>
                  {link.value.text}
                </TextLink>
              </Link>
            </Stretch>
          )}
        </Stack>
      </Box>

      {(((footerLink.value.href || footerText.value) && params.footerBackgroundColor && params.footerTextColor) ||
        footerText.editable) && (
        <Box
          backgroundColor={params.footerBackgroundColor}
          borderBottomLeftRadius="m"
          borderBottomRightRadius="m"
          paddingX={{ initial: '6', md: '10' }}
          paddingY="3">
          <Stack alignX="center">
            <Stack.Item as="span">
              {footerText.value || footerText.editable ? (
                <Box as="span" paddingRight={footerLink.value.href || footerLink.editable ? 2 : 0}>
                  <Text size="BodyS" display="inline" weight="bold" color={params.footerTextColor}>
                    {wrap(footerText)}
                  </Text>
                </Box>
              ) : null}
              {footerLink.value.href || footerLink.editable ? (
                <Text size="BodyS" display="inline">
                  <Link
                    editable={footerLink.editable}
                    linkType={footerLink.value.linktype}
                    linkValue={footerLink.value}>
                    <TextLink target={footerLink.value.target} color={params.footerTextColor}>
                      {footerLink.value.text}
                    </TextLink>
                  </Link>
                </Text>
              ) : null}
            </Stack.Item>
          </Stack>
        </Box>
      )}
    </Card>
  );
};

export default HeroCard;

import React, { FC } from 'react';

export const CheckmarkIconFilled: FC = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ verticalAlign: 'text-top' }}>
    <circle cx="18" cy="18" r="18" fill="#4BA867" />
    <path
      d="M14.9976 24.0002C14.8661 24.0009 14.7357 23.9757 14.6139 23.926C14.4921 23.8762 14.3814 23.8029 14.288 23.7102L10.29 19.7102C10.1018 19.5219 9.99609 19.2665 9.99609 19.0002C9.99609 18.7339 10.1018 18.4785 10.29 18.2902C10.4782 18.1019 10.7335 17.9961 10.9997 17.9961C11.2658 17.9961 11.5211 18.1019 11.7093 18.2902L14.9976 21.5902L24.2829 12.2902C24.4711 12.1019 24.7263 11.9961 24.9925 11.9961C25.2587 11.9961 25.5139 12.1019 25.7022 12.2902C25.8904 12.4785 25.9961 12.7339 25.9961 13.0002C25.9961 13.2665 25.8904 13.5219 25.7022 13.7102L15.7073 23.7102C15.6139 23.8029 15.5031 23.8762 15.3813 23.926C15.2596 23.9757 15.1292 24.0009 14.9976 24.0002Z"
      fill="white"
    />
  </svg>
);
